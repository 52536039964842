import { LocationSearchItem } from '@deckee/api-app-main';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';

const DEFAULT_SEARCH_TERM = 'boat ramp';

const useLocationSearch = ({ latitude, longitude, onSearchInput }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [hasError, setError] = useState(false);

  const [searchLocationItems, setSearchLocationItems] = useState<
    LocationSearchItem[]
  >([]);

  const fetchLocationDetails = async (
    searchLocationItem: LocationSearchItem,
  ) => {
    const placeDetailsResponse = await fetch(
      `/api/search/place/${searchLocationItem.placeId}`,
    );
    const { latitude, longitude } = await placeDetailsResponse.json();
    searchLocationItem.latitude = latitude;
    searchLocationItem.longitude = longitude;

    return searchLocationItem;
  };

  const updateLocationSearchTerm = useMemo(
    () =>
      debounce(200, async (event, controller?: AbortController) => {
        try {
          if (controller?.signal?.aborted) {
            return;
          }

          if (!latitude || !longitude) {
            return;
          }
          setIsLoading(true);

          const termText = event.target.value;
          const searchTermValue = termText.trim();

          const res = await fetch(
            `/api/search?${qs.stringify({
              searchTerm: searchTermValue,
              latitude,
              longitude,
            })}`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
              method: 'GET',
              signal: controller?.signal,
            },
          );

          setSearchLocationItems(
            (await res.json()).data?.sort((a, b) => {
              // Sort results with a placeId first
              if (a.placeId && !b.placeId) {
                return -1;
              }
              if (!a.placeId && b.placeId) {
                return 1;
              }
              return 0;
            }),
          );
        } catch (error) {
          if (controller?.signal?.aborted) {
            // It's not an issue, another request was initiated
            return;
          }
          setError(true);
        } finally {
          setIsLoading(false);
        }
      }),
    [latitude, longitude],
  );

  useEffect(() => {
    if (hasError) {
      setError(false);
    }

    const hasChanged = searchTerm ? isDirty : false;

    onSearchInput(searchTerm, hasChanged);
    if (!searchTerm || !isDirty) {
      return;
    }

    const controller = new AbortController();
    updateLocationSearchTerm(
      { target: { value: searchTerm || DEFAULT_SEARCH_TERM } },
      controller,
    );

    return () => {
      controller.abort();
    };
  }, [searchTerm, updateLocationSearchTerm, hasError]);

  return {
    isDirty,
    isLoading,
    setIsDirty,
    setSearchTerm,
    fetchLocationDetails,
    searchTerm,
    hasError,
    searchLocationItems,
    updateLocationSearchTerm,
  };
};

export default useLocationSearch;
